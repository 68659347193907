import http from '../axios/axios_conf';
import api from '../routers/routers.js';

class AuthService {
  async login(user) {
    const response = await http.post( api.users.login.endpoint,{
          email: user.email,
          password: user.password,
          source:'web'
      });
      
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
    
    return response.data;
  }

  async logout() {
    const response = await http.post( api.users.logout.endpoint, null);

    return response;
  }
}

export default new AuthService();