const Api = {

    'users' : {
        'login' : { 'method' : 'POST', 'endpoint' : '/users/token' },
        'logout' : { 'method' : 'POST', 'endpoint' : '/users/logout' },
        'token_refresh' : { 'method' : 'POST', 'endpoint' : '/users/refresh' },
        'create' : { 'method' : 'POST', 'endpoint' : '/users/signup' },
        'profile' : { 'method' : 'GET', 'endpoint' : '/users/my_profile' },
        'update_profile' : { 'method' : 'PUT', 'endpoint' : '/users/my_profile' },
        'update_password' : { 'method' : 'PUT', 'endpoint' : '/users/my_profile/change_password' },
        'restore_password' : { 'method' : 'PUT', 'endpoint' : 'users/my_profile/restore_password' },
        'select_building' : { 'method' : 'PUT', 'endpoint' : '/users/select_building' },
        'search_user' : {'method' : 'GET', 'endpoint' : '/users/search_user/'},
        'my_buildings' : { 'method' : 'GET', 'endpoint' : '/users/My_buildings' },
        'profile_picture' : { 'method' : 'POST', 'endpoint' : '/users/upload_profile_picture' }, 
        'update_user' : { 'method' : 'PUT', 'endpoint' : '/users/my_profile_sudo?user_uuid=' }, 
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/user/profile/delete/' },
    },

    'buildings' :{
        'get' : { 'method' : 'GET', 'endpoint' : '/buildings' },
        'create' : { 'method' : 'POST', 'endpoint' : '/buildings' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/buildings/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/buildings/' },
    },

    'building_admins':{
        'get' : { 'method' : 'GET', 'endpoint' : '/building_admins' },
        'add_admin' : { 'method' : 'POST', 'endpoint' : '/building_admins' },
        'my_buildings' : { 'method' : 'GET', 'endpoint' : '/buildings_im_admin' },
        'sudo' : { 'method' : 'POST', 'endpoint' : '/sudo_building_admins' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/building_admins/' },
    },

    'building_type' :{
        'get' : { 'method' : 'GET', 'endpoint' : '/building_type' },
        'create' : { 'method' : 'POST', 'endpoint' : '/building_type' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/building_type/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/building_type/' },
    },
    
    'location':{
        'get' : { 'method' : 'GET', 'endpoint' : '/location/city' }
    },

    'departments':{
        'get' : { 'method' : 'GET', 'endpoint' : '/departments' },
        'create' : { 'method' : 'POST', 'endpoint' : '/departments' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/departments/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/departments/' },
        'payment_template' : { 'method' : 'GET', 'endpoint' : '/department_excel_file_payments_template' },
        'payment_template_user' : { 'method' : 'GET', 'endpoint' : '/department_user_excel_file_template' },
        'upload_excel': {'method' : 'POST', 'endpoint' : '/department_excel_file_payments' },
        'upload_excel_user': {'method' : 'POST', 'endpoint' : '/department_user_excel_file' },
    },

    'departments_users':{
        'assing' : { 'method' : 'POST', 'endpoint' : '/department_users' },
        'list' : { 'method' : 'GET', 'endpoint' : '/department_users' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/department_users' },
        'assignPrincipal' : { 'method' : 'PUT', 'endpoint' : '/department_users' },
    },

    'dni_types':{
        'get' : { 'method' : 'GET', 'endpoint' : '/dni_types' },
    },

    'sales':{
        'get' : { 'method' : 'GET', 'endpoint' : '/sales' },
        'get_admin' : { 'method' : 'GET', 'endpoint' : '/sales_sudo' },
        'create' : { 'method' : 'POST', 'endpoint' : '/sales' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/sales/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/sales/{sale_uuid}?sales_uuid=' },
    },

    'documents':{
        'get' : { 'method' : 'GET', 'endpoint' : '/documents' },
        'create' : { 'method' : 'POST', 'endpoint' : '/upload_document' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/documents/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/documents/' },
    },

    'communications':{
        'get' : { 'method' : 'GET', 'endpoint' : '/communications' },
        'create' : { 'method' : 'POST', 'endpoint' : '/communications' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/communications/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/communications/' },
    },

    'posts':{
        'get' : { 'method' : 'GET', 'endpoint' : '/posts' },
        'create' : { 'method' : 'POST', 'endpoint' : '/posts' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/posts/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/posts/' },
    },

    'events':{
        'get' : { 'method' : 'GET', 'endpoint' : '/events' },
        'create' : { 'method' : 'POST', 'endpoint' : '/create_event' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/events/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/events/' },
    },

    'visits':{
        'get' : { 'method' : 'GET', 'endpoint' : '/visits' },
        'create' : { 'method' : 'POST', 'endpoint' : '/visits' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/visits/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/visits/' },
    },

    'deliverys':{
        'get' : { 'method' : 'GET', 'endpoint' : '/delivery' },
        'create' : { 'method' : 'POST', 'endpoint' : '/delivery' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/delivery/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/delivery/' },
    },

    'building_spaces':{
        'get' : { 'method' : 'GET', 'endpoint' : '/building_spaces' },
        'create' : { 'method' : 'POST', 'endpoint' : '/building_spaces' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/building_spaces/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/building_spaces/' },
    },

    'publicity':{
        'get' : { 'method' : 'GET', 'endpoint' : '/publicity' },
        'create' : { 'method' : 'POST', 'endpoint' : '/publicity' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/publicity/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/publicity/' },
    },

    'company':{
        'get' : { 'method' : 'GET', 'endpoint' : '/company' },
        'create' : { 'method' : 'POST', 'endpoint' : '/company' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/company/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/company/' },
    },
    
    'banners':{
        'get' : { 'method' : 'GET', 'endpoint' : '/banners' },
        'create' : { 'method' : 'POST', 'endpoint' : '/banners' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/banners/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/banners/' },
    },

    'ads':{
        'get' : { 'method' : 'GET', 'endpoint' : '/ads' },
        'create' : { 'method' : 'POST', 'endpoint' : '/ads' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/ads/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/ads/' },
    },

    'building_space_schedules':{
        'get' : { 'method' : 'GET', 'endpoint' : '/building_space_schedules' },
        'create' : { 'method' : 'POST', 'endpoint' : '/building_space_schedules' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/building_space_schedules/{building_space_schedules_uuid}?building_space_schedule_uuid=' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/building_space_schedules/{building_space_schedules_uuid}?building_space_schedule_uuid=' },
    },

    'buildings_space_logs':{
        'get' : { 'method' : 'GET', 'endpoint' : '/building_space_logs' },
        'getAvailableSchedules' : { 'method' : 'GET', 'endpoint' : '/building_space_logs' },
        'create' : { 'method' : 'POST', 'endpoint' : '/building_space_logs' },
        'aprobe' : { 'method' : 'PUT', 'endpoint' : '/approve_building_space_logs?building_space_log_uuid=' },
        'update' : { 'method' : 'PUT', 'endpoint' : '/building_space_logs/' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/approve_building_space_logs/' },
    },

    'regions':{
        'get' : { 'method' : 'GET', 'endpoint' : '/location/regions?country_name=chile&limit=1000' },
    },

    'deparment_messages' : {
        'get' : { 'method' : 'GET', 'endpoint' : '/department_messages' },
        'last' : { 'method' : 'GET', 'endpoint' : '/departments_latest_message' },
        'create' : { 'method' : 'POST', 'endpoint' : '/department_messages' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/department_messages' },
    },
    
    'department_payments':{
        'get' : { 'method' : 'GET', 'endpoint' : '/department_payments' },
        'create' : { 'method' : 'POST', 'endpoint' : '/department_payments' },
        'delete' : { 'method' : 'DELETE', 'endpoint' : '/department_payments' },
        'send_payment' : { 'method' : 'DELETE', 'endpoint' : '/department_send_payments' },
        'approve_payment' : { 'method' : 'DELETE', 'endpoint' : '/department_approve_payments' },

    }
    
};

export default Api;
  
  
    
 