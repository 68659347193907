import Vue from 'vue'
import VueRouter from 'vue-router'
import super_admin from './routers/super-admin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      alias: '/login',
      name: "login",
      component: () => import("@/view/pages/authentication/login"),
      meta: {
        layout: "full",
        redirectIfLoggedIn: true
      },
    },
    {
      path: "/recover",
      name: "recover",
      component: () => import("@/view/pages/authentication/recover"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("@/view/pages/privacy"),
      meta: {
        title: 'Privacy',
        layout: "full",
      },
    },
    {
      path: "/user/profile",
      name: "profile",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Perfil"
          }
        ]
      },
    },
    {
      path: "/user/profile/notifications",
      name: "profile-notifications",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Perfil"
          }
        ]
      },
    },
    {
      path: "/user/profile/activity",
      name: "profile-activity",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Perfil"
          }
        ]
      },
    },
    {
      path: "/user/profile/security",
      name: "profile-security",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Perfil"
          }
        ]
      },
    },
    {
      path: "/user/profile/password-change",
      name: "profile-password-change",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Perfil"
          }
        ]
      },
    },
    {
      path: "/user/profile/connect-with-social",
      name: "profile-connect-with-social",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Perfil"
          }
        ]
      },
    },
    {
      path: "/user/profile/personal-information",
      name: "profile-personal-information",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Perfil"
          }
        ]
      },
    },
    ...super_admin,
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/','/recover','/privacy'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn) {
    next('/login');
  }else {
    next();
  }

  if (to.meta.redirectIfLoggedIn && loggedIn) {
    next('/dashboard');
  }
  
});

export default router