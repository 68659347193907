export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/view/main/dashboards/ecommerce"),
  },
  {
    path: "/admin/building/:id",
    name: "get-building",
    component: () => import("@/view/pages/buildings/admin"),
    meta: {
      title: "Ver edificio",
      breadcrumb: [
        {
          text: "Edificios",
        },
      ],
    },
  },
  {
    path: "/admin/building/departments/:id",
    name: "get-building-departments",
    component: () => import("@/view/pages/buildings/admin"),
    meta: {
      title: "Ver edificios",
      breadcrumb: [
        {
          text: "Edificios",
        },
      ],
    },
  },
  {
    path: "/admin/building/admins/:id",
    name: "get-building-admins",
    component: () => import("@/view/pages/buildings/admin"),
    meta: {
      title: "Administrar edificio",
      breadcrumb: [
        {
          text: "Edificios",
        },
      ],
    },
  },
  {
    path: "/admin/departament/:id",
    name: "get-departament-admins",
    component: () => import("@/view/pages/buildings/departaments"),
    meta: {
      title: "ver departamento",
      breadcrumb: [
        {
          text: "Edificios",
        },
      ],
    },
  },
  {
    path: "/admin/departament/visits/:id",
    name: "get-departament-visits-admins",
    component: () => import("@/view/pages/buildings/departaments"),
    meta: {
      title: "ver visitas",
      breadcrumb: [
        {
          text: "Visitas",
        },
      ],
    },
  },
  {
    path: "/admin/departament/packages/:id",
    name: "get-departament-package-admins",
    component: () => import("@/view/pages/buildings/departaments"),
    meta: {
      title: "ver visitas",
      breadcrumb: [
        {
          text: "Visitas",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/noticias",
    name: "admin-noticias",
    component: () => import("@/view/pages/noticias"),
    meta: {
      title: "listado",
      breadcrumb: [
        {
          text: "noticias",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/marketplace",
    name: "admin-sales-create",
    component: () => import("@/view/pages/sales"),
    meta: {
      title: "listado",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/modulos/documentos",
    name: "admin-document-create",
    component: () => import("@/view/pages/documents"),
    meta: {
      title: "listado",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/modulos/comunicados",
    name: "admin-communications-create",
    component: () => import("@/view/pages/communications"),
    meta: {
      title: "listado",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/configuracion/edificios/tipos",
    name: "admin-configuration-building-type",
    component: () => import("@/view/pages/buildingsType"),
    meta: {
      title: "Tipos",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/configuracion/edificios",
    name: "admin-configuration-building",
    component: () => import("@/view/pages/buildingsType"),
    meta: {
      title: "Edificios",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/modulos/eventos",
    name: "admin-communications-create",
    component: () => import("@/view/pages/events"),
    meta: {
      title: "listado",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/modulos/reservas",
    name: "admin-communications-reservas",
    component: () => import("@/view/pages/reservas"),
    meta: {
      title: "listado",
      breadcrumb: [
        {
          text: "Reservas",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/reservas/:id",
    name: "admin-communications-specific-reserva",
    component: () => import("@/view/pages/buildings_spaces"),
    meta: {
      title: "Reservas",
      breadcrumb: [
        {
          text: "Reservas",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/reservas/aprobar/:id",
    name: "admin-communications-specific-aprobe",
    component: () => import("@/view/pages/buildings_spaces"),
    meta: {
      title: "Aprobar",
      breadcrumb: [
        {
          text: "Aprobar",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/pymes",
    name: "admin-publicidad",
    component: () => import("@/view/pages/ads"),
    meta: {
      title: "Pymes",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/modulos/pymes/categorias",
    name: "admin-publicidad-category",
    component: () => import("@/view/pages/ads"),
    meta: {
      title: "Pymes",
      breadcrumb: [
        {
          text: "Pymes",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/pymes/empresas",
    name: "admin-publicidad-company",
    component: () => import("@/view/pages/ads"),
    meta: {
      title: "Pymes",
      breadcrumb: [
        {
          text: "Pymes",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/pymes/anuncios",
    name: "admin-publicidad-ads",
    component: () => import("@/view/pages/ads"),
    meta: {
      title: "Pymes",
      breadcrumb: [
        {
          text: "Pymes",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/visitas",
    name: "admin-building-visits",
    component: () => import("@/view/pages/visits"),
    meta: {
      title: "Visitas",
      breadcrumb: [
        {
          text: "Visitas",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/paquetes",
    name: "admin-building-package",
    component: () => import("@/view/pages/packages"),
    meta: {
      title: "paquetes",
      breadcrumb: [
        {
          text: "Paquetes",
        },
      ],
    },
  },
  {
    path: "/admin/configuracion/edificios/sponsors",
    name: "admin-building-sponsors",
    component: () => import("@/view/pages/banners"),
    meta: {
      title: "sponsors",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/configuracion/edificios/sponsors/company",
    name: "admin-building-sponsors-company",
    component: () => import("@/view/pages/banners"),
    meta: {
      title: "Empresa",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/modulos/productos-servicios",
    name: "admin-building-products-servicies",
    component: () => import("@/view/pages/products_and_services"),
    meta: {
      title: "Productos y Servicios",
      breadcrumb: [
      ],
    },
  },
  {
    path: "/admin/modulos/espacios/reservar",
    name: "get-building-spaces-reserve",
    component: () => import("@/view/pages/buildings_spaces"),
    meta: {
      title: "Espacios de reserva",
      breadcrumb: [
        {
          text: "reservar",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/espacios/aprobar",
    name: "get-building-spaces-aprobe",
    component: () => import("@/view/pages/buildings_spaces"),
    meta: {
      title: "Mensajes",
      breadcrumb: [
        {
          text: "mensajes",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/mensajes",
    name: "get-building-menssages",
    component: () => import("@/view/pages/buildings_menssages"),
    meta: {
      title: "mensajes",
      breadcrumb: [
        {
          text: "Mensajes",
        },
      ],
    },
  },
  {
    path: "/admin/modulos/pagos",
    name: "get-building-payments",
    component: () => import("@/view/pages/payments"),
    meta: {
      breadcrumb: [
        {
          text: "pagos",
        },
      ],
    },
  },
];
