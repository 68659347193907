const initialState = {
    selectBuilding : null,
}

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
      getBuildingSelect({ commit }) {
        commit('get');
      },
      setBuildingSelect({ commit }, token) {
        commit('set', token);
      },
    },
    mutations: {
    set(state,token) {
      localStorage.setItem('home_app_selectBuilding',token);
    },
    },
    getters: {
      getUserBuildingToken: state => {
        return localStorage.getItem('home_app_selectBuilding')
      }
    }
    
  }
  