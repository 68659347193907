import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import themeConfig from './theme-config'
import {user} from './user'
import {auth} from './auth';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    themeConfig,
    user,
    auth
  },
})
 