export default {
  state: {
    version: "1.0",
    theme: "light", // light - dark
    contentWidth: "full", // full - boxed
    sidebarCollapsed: false,
    sidebarCollapseButton: true,
    layout: "horizontal", // vertical - horizontal
    navigationFull: true,
    navigationBg: false,
    direction: "ltr", // ltr - rtl
  }
}
