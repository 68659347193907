import axios from  'axios'

const $axios = axios.create({
    baseURL: 'https://devapi.homeapp.cl',//'https://devapi.homeapp.cl', //''
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
});
  
$axios.interceptors.request.use(async config => {
    if(localStorage.getItem('user') !== null){
        let token = JSON.parse(localStorage.getItem('user'));
        config.headers['Authorization'] = `Bearer ${token.access_token}`;
    }

    return config
})
  
export default $axios

